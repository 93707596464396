<template>
  <div id="success-payment-page">
      <NavPackage/>
      <div class="justify-content-center align-items-center d-flex flex-column mt-100">
        <img src="@/assets/images/success-payment.svg" class="mb-4" style="height: 128px; width: 128px" alt="">
        <span class="title-sp-payment mb-4">Payment successfull</span>
        <span class="sub-title-sp-payment">Your payment has been successfully received.</span>
        <span class="sub-title-sp-payment mb-4">A copy of your receipt will be sent to your email.</span>
        <span @click="goToEnterprise()" class="btn-sp">Go to Homepage</span>
      </div>
  </div>
</template>

<script>
import NavPackage from '../../../components/nav-packages.vue'
export default {
  name: 'PaymentSuccess',
  components: {
      NavPackage
  },
  mounted() {
    //   this.$ga.page('payment/success')
      this.$ga.event('payment-success-page-enterprise', 'actionx', 'labelx', 1)
    sessionStorage.setItem('newAdminEnterprise', true)
  },
  methods:{
    goToEnterprise(){
        this.$store.commit('state/SET_STATE', 'enterprise')
        this.$store.dispatch('enterprise/getMyEnterprise')
        .then(()=> {
          this.$router.push('/enterprise/admin/customize')
        })
    }
  }
}
</script>

<style scoped>

</style>